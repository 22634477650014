/**
 * Dealer Center page category.
 *
 * NOTE: this category is used in both main page and 404 page definitions.
 *
 * @type {string}
 */
export const DEALER_CENTER_PAGE_CATEGORY = 'industry';

/*
 * Individual Dealer Center module creative IDs
 */
export const LEAD_FORM_CREATIVE_ID = 'dealercenter-lead-form';
export const SECTION_HEADER_CREATIVE_ID = 'dealercenter-section-header';
export const STATISTICS_CREATIVE_ID = 'dealercenter-statistics';
export const PARTNERSHIPS_CREATIVE_ID = 'dealercenter-partnerships';
export const VERSUS_OTHERS_CREATIVE_ID = 'dealercenter-versus-others';

export const VIDEO_CAROUSEL_CREATIVE_ID = 'dealercenter-video-carousel';

export const RICH_TEXT_CREATIVE_ID = 'dealercenter-rich-text';
export const FEATURE_GRID_CREATIVE_ID = 'dealercenter-features-grid';
export const VIDEO_BACKGROUND_CREATIVE_ID = 'dealercenter-video-bkg';

export const TESTIMONIALS_CREATIVE_ID = 'dealercenter-testimonials';
