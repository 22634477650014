import React from 'react';
import PropTypes from 'prop-types';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { CmsModel } from 'client/data/models/cms';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { SEOHead } from 'site-modules/shared/pages/seo';
import { DealerCenterGlobalNav } from 'site-modules/microsite/dealers/components/dealer-center-global-nav/dealer-center-global-nav';
import { DealerCenterFooter } from 'site-modules/microsite/dealers/components/dealer-center-footer/dealer-center-footer';
import { DEALER_CENTER_GLOBAL_NAV_PATH } from 'site-modules/microsite/dealers/constants/dealer-center';

import './dealer-center-decorator.scss';

export const DealerCenterDecorator = ({ children, ...restProps }) => (
  <div className="dealer-center-page">
    <SEOHead {...restProps} />
    <div className="dealer-center-page-contents-container pos-r overflow-hidden">
      <figure className="pos-a w-100 m-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 4615" aria-hidden>
          <linearGradient
            id="line49"
            x1={-78.195}
            x2={965.413}
            y1={4753.203}
            y2={793.686}
            gradientTransform="matrix(1 0 0 -1 0 4616)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} style={{ stopColor: '#7aafff' }} />
            <stop offset={1} style={{ stopColor: '#f0f6ff' }} />
          </linearGradient>
          <path
            fill="url(#line49)"
            fillOpacity=".15"
            d="m0 3572.1 1198.4-1127.9 1.6-331.1L0 1046.6V773l899.6-496.2c1.8-1 3.8-1.5 5.8-1.5H1200v127.9L750.5 774.9c-43.5 36-39.8 103.7 7.2 134.8l442.3 292.4v2331.3S119.2 4489.6 0 4596.5V3572.1z"
          />
        </svg>
      </figure>
      <ErrorBoundary>
        <DealerCenterGlobalNav />
      </ErrorBoundary>
      {children}
      <DealerCenterFooter />
    </div>
  </div>
);

DealerCenterDecorator.propTypes = {
  children: PropTypes.node.isRequired,
};

DealerCenterDecorator.preload = store => {
  const preloader = new ModelPreloader(store);
  preloader.resolve(`content["${DEALER_CENTER_GLOBAL_NAV_PATH}"]`, CmsModel);

  return preloader.load();
};
