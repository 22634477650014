import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Nav from 'reactstrap/lib/Nav';
import Button from 'reactstrap/lib/Button';
import Container from 'reactstrap/lib/Container';
import classNames from 'classnames';
import { get } from 'lodash';

import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { bindToContent, CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { EdmundsLogo } from 'site-modules/shared/components/edmunds-logo/edmunds-logo';
import { AnimatedCollapse } from 'site-modules/shared/utils/collapse-utils';
import {
  renderNavEntries,
  renderNavBarEntries,
  getContactData,
} from 'site-modules/microsite/dealers/components/dealer-center-global-nav/dealer-center-global-nav-helper';
import {
  DEALER_CENTER_GLOBAL_NAV_PATH,
  DEALER_PORTAL_URL,
} from 'site-modules/microsite/dealers/constants/dealer-center';

import './dealer-center-global-nav.scss';

const SMALL_SCREEN_BREAKPOINTS = ['xs', 'sm', 'md'];
const DEALERS_URL = 'http://dealers.edmunds.com';

export const DealerCenterGlobalNavUI = ({ globalNavData, breakpoint }) => {
  const [openedEntryId, setOpenedEntryId] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmall, setIsSmall] = useState(null);
  const [renderEntries, setRenderEntries] = useState(() => renderNavEntries);

  useEffect(() => {
    if (!isMenuOpen) {
      setOpenedEntryId('');
    }
  }, [isMenuOpen]);

  const toggleSubMenu = useCallback(
    e => {
      e.preventDefault();
      const id = e.target.id;
      setOpenedEntryId(openedEntryId === id ? '' : id);
    },
    [openedEntryId]
  );

  const [entries, setEntries] = useState(renderNavEntries(globalNavData, toggleSubMenu));

  useEffect(() => {
    setIsSmall(SMALL_SCREEN_BREAKPOINTS.includes(breakpoint));
  }, [breakpoint]);

  useEffect(() => {
    setIsMenuOpen(false);
    setOpenedEntryId('');
    setRenderEntries(isSmall ? () => renderNavBarEntries : () => renderNavEntries);
  }, [isSmall]);

  useEffect(() => {
    setEntries(renderEntries(globalNavData, toggleSubMenu));
  }, [globalNavData, renderEntries, toggleSubMenu, openedEntryId]);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  const getOpenedEntryChildren = () => (entries.find(entry => entry.id === openedEntryId) || {}).children;

  const contactData = getContactData(globalNavData);

  const getEdmundsLogo = () => <EdmundsLogo className="pt-0" path={DEALERS_URL} trackingId="nav_logo_click" />;

  const renderMenu = () => (
    <Nav className="d-none w-100 d-flex flex-row navbar-toggleable-md">
      {getEdmundsLogo()}
      <div
        className={classNames('links d-flex justify-content-center flex-row w-100', {
          'collapse-open': !!openedEntryId,
        })}
      >
        {entries.map(entry => entry.el)}
      </div>
      <Button
        color="link"
        href={contactData.url}
        className="heading-5 px-0 py-1 my-0 font-weight-normal text-decoration-none"
        data-tracking-id="nav_menu_item"
        data-tracking-value={contactData.title}
      >
        {contactData.title}
        <hr />
      </Button>
      <Button
        color="link"
        href={DEALER_PORTAL_URL}
        className="heading-5 px-1 py-0 my-0 text-decoration-none font-weight-normal"
        data-tracking-id="nav_menu_item"
        data-tracking-value="Sign In"
      >
        <span className="icon-user3 px-0_5" aria-hidden />
        <span>Sign In</span>
        <hr />
      </Button>
    </Nav>
  );

  const renderSubmenu = () => (
    <AnimatedCollapse className="collapse-subnav d-flex justify-content-center w-100" isOpen={!!openedEntryId}>
      <ul className="list-unstyled m-0">{getOpenedEntryChildren()}</ul>
    </AnimatedCollapse>
  );

  const renderHamburgerMenu = () => (
    <Nav className="w-100 d-flex flex-column  navbar-toggleable-md">
      <div className="py-1 w-100">
        {getEdmundsLogo()}
        <Button
          color="link"
          onClick={toggleMenu}
          aria-label="Menu"
          className={classNames('mb-0 p-0 float-right size-24', {
            'icon-cross2': isMenuOpen,
            'icon-menu7': !isMenuOpen,
          })}
          {...(isMenuOpen ? { 'data-tracking-id': 'nav_menu_item', 'data-tracking-value': 'Expand Menu' } : {})}
        />
      </div>
    </Nav>
  );

  const renderOpenedHamburgerMenu = () => (
    <AnimatedCollapse
      className="collapse-navbar justify-content-center w-100 text-yellow-100"
      isOpen={isMenuOpen}
      overflowOnExpanded
    >
      <ul className="list-unstyled m-0 px-1 py-1">
        {entries.map(entry => entry.el)}
        <li>
          <Button
            color="link"
            href={contactData.url}
            className="heading-4 px-0 py-1 text-yellow-100 font-weight-bold"
            data-tracking-id="nav_menu_item"
            data-tracking-value={contactData.title}
          >
            {contactData.title}
          </Button>
        </li>
        <hr />
        <li>
          <Button
            color="link"
            href={DEALER_PORTAL_URL}
            className="heading-4 px-0 py-1 text-yellow-100 text-decoration-none font-weight-bold"
            data-tracking-id="nav_menu_item"
            data-tracking-value="Sign In"
          >
            <span className="icon-user3 pr-0_5" aria-hidden />
            <span>Sign In</span>
          </Button>
        </li>
      </ul>
    </AnimatedCollapse>
  );

  return (
    <div className="dealer-center-global-nav w-100 bg-blue-05" data-tracking-parent="dealercenter-global-nav">
      <Container className="px-0" fluid>
        <Container>
          <Row>
            <Col className="px-1">{isSmall ? renderHamburgerMenu() : renderMenu()}</Col>
          </Row>
        </Container>
        {isSmall ? renderOpenedHamburgerMenu() : renderSubmenu()}
      </Container>
    </div>
  );
};

DealerCenterGlobalNavUI.propTypes = {
  globalNavData: CmsEntities.Content,
  breakpoint: PropTypes.string,
};

DealerCenterGlobalNavUI.defaultProps = {
  globalNavData: DEFAULT_CONTENT,
  breakpoint: '',
};

export const stateToPropsConfig = {
  globalNavData: bindToContent(DEALER_CENTER_GLOBAL_NAV_PATH),
};

export const mapStateToProps = state => ({
  breakpoint: get(state, 'pageContext.breakpointDeprecated'),
});

export const DealerCenterGlobalNav = connect(mapStateToProps)(
  connectToModel(DealerCenterGlobalNavUI, stateToPropsConfig)
);
